// TODO: Move code unrelated to state out of this duck (side effects, helpers, etc)
// general code refactoring (duplicate arguments, reducer, etc)

// Libraries
import { createSelector } from 'reselect';
import { shuffle, sortBy, isEmpty } from 'underscore';
import isFuture from 'date-fns/isFuture';
import parseISO from 'date-fns/parseISO';
import FingerprintJS from '@fingerprintjs/fingerprintjs';
import { v4 as uuid } from 'uuid';

// Utilities
import {
  upsertResponse,
  createSubmission,
  completeSubmission,
  createSectionSubmission,
} from 'api/rest';
import * as responseService from 'api/responses';
import createReducer from 'state/helpers/createReducer';
import { deleteEntry, mergeHistoryUpdates, patchEntry, postEntry } from 'state/helpers/responses';
import { createMap, jsonaDeserialize, numberRegex, sumQuestionValues } from 'common/utils/helpers';
import { shouldRandomizeInputs, checkSkipLogic } from 'engagement/utils/helpers';
import { logParticipantSubmission } from 'engagement/utils/analytics';

// Enums
import { SKIP, SHOW } from 'engagement/enums/LogicalActions';
import QuestionFormat from 'engagement/enums/QuestionFormat';
import Rules from 'engagement/enums/Rules';
import { ValidationTypes, ValidationEnable, Validations } from 'engagement/enums/singleTextBox';

// Initial State
export const INITIAL_STATE = {
  stateHydrated: false,
  engagementSerialized: null,
  submissionTracked: false,
  submission: null,
  sectionsSubmitted: [],
  currentSectionPosition: 1,
  questionValues: {},
  questionErrors: {},
  preview: false,
  fingerprint: null,
  responseHistory: [],
  responseInputsMap: {},
  fingerprintConsent: window.gon.brand.consent_digital_fingerprint ? true : null,
};

const findNextSectionPosition = (skipSections, sections, currentSectionPosition) => {
  const nextSectionPosition = (position) =>
    skipSections.includes(position) ? nextSectionPosition(position + 1) : position;

  const position = nextSectionPosition(currentSectionPosition + 1);

  if (!sections[position]) {
    return null;
  }

  return position;
};

// Action Types
export const SET_PERSISTED_ENGAGEMENT = 'SET_PERSISTED_ENGAGEMENT';
export const CLEAR_USER_DATA = 'CLEAR_USER_DATA';
export const UPDATE_SECTIONS_SUBMITTED = 'UPDATE_SECTIONS_SUBMITTED';
export const UPDATE_CURRENT_POSITION = 'UPDATE_CURRENT_POSITION';
export const UPDATE_QUESTION_VALUE = 'UPDATE_QUESTION_VALUE';
export const VALIDATE_CURRENT_SECTION = 'VALIDATE_CURRENT_SECTION';
export const SAVE_SUBMISSION = 'SAVE_SUBMISSION';
export const UPDATE_FINGERPRINT_CONSENT = 'UPDATE_FINGERPRINT_CONSENT';
export const PUSH_RESPONSE_ENTRY = 'PUSH_RESPONSE_ENTRY';
export const UPDATE_RESPONSE_INPUTS_MAP = 'UPDATE_RESPONSE_INPUTS_MAP';
export const RESET_RESPONSE_HISTORY = 'RESET_RESPONSE_HISTORY';
export const TRACK_SUBMISSION = 'TRACK_SUBMISSION';

// Selectors
export const getState = (rootState) => rootState.engagement;

export const getStateHydrated = createSelector(getState, (state) => state.stateHydrated);
export const getSerializedEngagement = createSelector(
  getState,
  (state) => state.engagementSerialized
);
export const getSubmission = createSelector(getState, (state) => state.submission);
export const getSectionsSubmitted = createSelector(getState, (state) => state.sectionsSubmitted);
export const getCurrentSectionPosition = createSelector(
  getState,
  (state) => state.currentSectionPosition
);
export const getQuestionValues = createSelector(getState, (state) => state.questionValues);
export const getQuestionErrors = createSelector(getState, (state) => state.questionErrors);
export const getPreview = createSelector(getState, (state) => state.preview);
export const getFingerprintConsent = createSelector(getState, (state) => state.fingerprintConsent);
export const getResponseHistory = createSelector(getState, (state) => state.responseHistory);
export const getResponseInputsMap = createSelector(getState, (state) => state.responseInputsMap);
export const getFingerprint = createSelector(getState, (state) => state.fingerprint);
export const getSubmissionTracked = createSelector(getState, (state) => state.submissionTracked);

export const deserializeEngagement = createSelector(
  [getSerializedEngagement],
  (engagementSerialized) => (engagementSerialized ? jsonaDeserialize(engagementSerialized) : null)
);

export const getSections = createSelector([deserializeEngagement], (engagement) =>
  engagement && engagement.sections
    ? sortBy(engagement.sections, 'position').reduce((accumulator, section, index) => {
        // Randomize inputs for questions in the engagement with randomized toggled on
        const sectionWithRandomizedInputs = {
          ...section,
          blocks: section.blocks.map((block) => {
            if (block.question && block.question.inputs) {
              const { question } = block;
              const { inputs } = question;
              return {
                ...block,
                question: {
                  ...question,
                  inputs: shouldRandomizeInputs(question)
                    ? shuffle(inputs)
                    : sortBy(inputs, 'position'),
                },
              };
            }
            return block;
          }),
        };
        accumulator[index + 1] = sectionWithRandomizedInputs;
        return accumulator;
      }, {})
    : []
);

export const engagementQuestions = createSelector([deserializeEngagement], (engagement) =>
  engagement && engagement.sections
    ? engagement.sections.reduce((accumulator, section) => {
        const sectionQuestions = section.blocks
          .filter((block) => block.question)
          .map((filteredBlock) => filteredBlock.question);
        accumulator.push(...sectionQuestions);
        return accumulator;
      }, [])
    : []
);

export const getEngagementQuestionsMap = createSelector(engagementQuestions, createMap);

const getQuestionInputs = createSelector(engagementQuestions, (questions) =>
  questions.reduce((accumulator, question) => {
    accumulator.push(...question.inputs);
    return accumulator;
  }, [])
);

const getQuestionWeights = createSelector(engagementQuestions, (questions) =>
  questions.reduce((accumulator, question) => {
    if (question.weights) accumulator.push(...question.weights);
    return accumulator;
  }, [])
);

export const getQuestionInputsMap = createSelector(getQuestionInputs, createMap);
const getQuestionWeightsMap = createSelector(getQuestionWeights, createMap);

export const getCurrentSection = createSelector(
  [getSections, getCurrentSectionPosition],
  (sections, currentSectionPosition) => {
    if (!sections[currentSectionPosition]) {
      return null;
    }
    return sections[currentSectionPosition];
  }
);

export const getCurrentQuestionValues = createSelector(
  [getQuestionValues, getCurrentSectionPosition],
  (questionValues, currentSectionPosition) => questionValues[currentSectionPosition]
);

export const generateSkipSections = createSelector(
  [getSections, getQuestionValues, engagementQuestions],
  (sections, questionValues, selectedEngagementQuestions) => {
    const logicalQuestions = selectedEngagementQuestions.filter(
      (question) => question.questionLogics && question.questionLogics.length
    );
    const responses = Object.values(questionValues);

    return logicalQuestions.reduce((accumulator, question) => {
      const { questionLogics, id } = question;

      questionLogics.forEach((questionLogic) => {
        const { logicalId, action } = questionLogic;
        const questionResponse = responses.find((response) => Object.keys(response).includes(id));
        const questionSection = Object.values(sections).find(
          (section) => Number(section.id) === logicalId
        );

        if (!questionSection.questionLogicEnabled) return;

        const shouldSkip = action === SKIP && checkSkipLogic(id, questionResponse, questionLogic);
        const shouldNotShow =
          action === SHOW && !checkSkipLogic(id, questionResponse, questionLogic);

        // Use index position, as React bases current/next/etc position off of ordered
        // sections and not the position attribute itself
        const indexPosition = Object.values(sections).indexOf(questionSection) + 1;

        if ((shouldSkip || shouldNotShow) && !accumulator.includes(indexPosition)) {
          accumulator.push(indexPosition);
        }
      });

      return accumulator;
    }, []);
  }
);

export const getCurrentProgress = createSelector(
  [deserializeEngagement, getCurrentSectionPosition],
  (engagement, currentSectionPosition) => {
    if (!engagement?.introEnabled) {
      return (currentSectionPosition - 1) * (100 / (engagement.sections.length - 1));
    }

    return currentSectionPosition * (100 / engagement.sections.length);
  }
);

export const getPreviousSectionPosition = createSelector(
  [generateSkipSections, getSections, getCurrentSectionPosition, deserializeEngagement],
  (skipSections, sections, currentSectionPosition, engagement) => {
    if (!engagement?.introEnabled && currentSectionPosition === 2) return null;

    const previousSectionPosition = (position) =>
      skipSections.includes(position) ? previousSectionPosition(position - 1) : position;

    const position = previousSectionPosition(currentSectionPosition - 1);

    if (!sections[position]) {
      return null;
    }

    return position;
  }
);

export const getNextSectionPosition = createSelector(
  [generateSkipSections, getSections, getCurrentSectionPosition],
  (skipSections, sections, currentSectionPosition) =>
    findNextSectionPosition(skipSections, sections, currentSectionPosition)
);

export const checkPreviewMode = createSelector(
  [getPreview, deserializeEngagement],
  (preview, engagement) => preview || isFuture(parseISO(engagement?.startTime))
);

// Actions
export const setPersistedEngagement =
  (keyPrefix, persistedState, engagement, preview, shouldClearStorage) => async (dispatch) => {
    const shouldClear = shouldClearStorage?.({ engagement, preview }, persistedState);

    if (shouldClear) {
      Object.keys(localStorage)
        .filter((key) => key.startsWith(keyPrefix))
        .forEach((key) => localStorage.removeItem(key));
    }

    dispatch({
      type: SET_PERSISTED_ENGAGEMENT,
      payload: {
        ...(shouldClear ? INITIAL_STATE : persistedState),
        engagementSerialized: engagement,
        stateHydrated: true,
        preview,
      },
    });
  };

export const clearUserData = () => ({ type: CLEAR_USER_DATA });

export const updateCurrentPosition = (currentSectionPosition) => ({
  type: UPDATE_CURRENT_POSITION,
  payload: { currentSectionPosition },
});

export const updateQuestionValue = (id, value, append) => ({
  type: UPDATE_QUESTION_VALUE,
  payload: { id, value, append },
});

const validateRequired = (question, values = {}) => {
  if (!question.required) return [];

  const errors = [];
  const inputValues = Object.values(values);

  if (!inputValues.length || inputValues.some(({ value }) => value === '')) {
    errors.push(Rules.REQUIRED);
  }

  if (question.format === QuestionFormat.MATRIX && inputValues.length < question.inputs.length) {
    errors.push(Rules.ALL_ROWS_REQUIRED);
  }

  return errors;
};

const validateChoicesLimit = (question, values = {}) => {
  const choicesLimit = question.displayData?.choicesLimit;
  if (!choicesLimit) return [];

  const errors = [];
  const choicesLimitNumber = parseInt(choicesLimit, 10);
  const exceededMaxChoices = Object.keys(values).length > choicesLimitNumber;

  if (exceededMaxChoices) errors.push(Rules.EXCEEDED_MAX_CHOICES);

  return errors;
};

const validateHardCapped = (question, values = {}) => {
  if (question.displayData?.capType !== 'hard_cap') return [];

  const errors = [];
  let usedBudget = 0;

  if (question.format === QuestionFormat.BUDGET_OPEN) {
    usedBudget = sumQuestionValues(values);
  } else {
    const inputs = Object.keys(values).map((id) =>
      question.inputs.find((input) => input.id === id)
    );
    usedBudget = inputs.reduce((sum, input) => {
      const amount = input.displayData?.amount;
      return amount ? sum + Number(amount) : sum;
    }, 0);
  }

  if (usedBudget > Number(question.displayData.budget)) {
    errors.push(Rules.BUDGET);
  }

  return errors;
};

const validateNumeric = (question, values = {}) => {
  if (question.format !== QuestionFormat.NUMERIC) return [];

  const errors = [];
  const inputValues = Object.values(values);
  const { decimalDigits, rangeStart, rangeEnd } = question.displayData;
  const valueRegex = numberRegex(Number(decimalDigits));

  if (inputValues.some(({ value }) => !valueRegex.test(value))) {
    errors.push(Rules.NUMBER);
  }

  const notInRange = inputValues.some(({ value }) => {
    if (value === '') return false;
    return Number(value) < Number(rangeStart) || Number(value) > Number(rangeEnd);
  });

  if (notInRange) errors.push(Rules.NUMBER_RANGE);

  return errors;
};

const validateBaseQuestion = (question, values = {}) => {
  if (question.format !== QuestionFormat.BASIC) return [];

  const errors = [];
  const { displayData } = question;

  if (displayData === null || displayData === undefined) return errors;

  const { enableValidation, validationType, regexp, characterCountMin, characterCountMax } =
    displayData;
  if (!enableValidation || enableValidation === ValidationEnable.DISABLED) return errors;

  const pattern = regexp || Validations[validationType]?.pattern;
  const questionValues = Object.values(values);

  switch (validationType) {
    case ValidationTypes.EMAIL:
    case ValidationTypes.WEBSITE:
    case ValidationTypes.CANADIAN_POSTAL_CODE:
    case ValidationTypes.UK_POSTAL_CODE:
    case ValidationTypes.ISRAEL_POSTAL_CODE:
    case ValidationTypes.US_ZIP_CODE:
    case ValidationTypes.NORTH_AMERICAN_PHONE_NUMBER:
    case ValidationTypes.UK_PHONE_NUMBER:
    case ValidationTypes.ISRAEL_PHONE_NUMBER:
      if (
        !questionValues.length ||
        questionValues.some(({ value }) => !new RegExp(pattern).test(value))
      ) {
        errors.push(Rules.VALIDATION_TYPE);
      }
      break;
    case ValidationTypes.CUSTOM:
      if (
        !questionValues.length ||
        questionValues.some(({ value }) => !new RegExp(pattern).test(value))
      ) {
        errors.push(Rules.REGULAR_EXPRESSION);
      }
      break;
    case ValidationTypes.CHARACTER_COUNT:
      if (
        (characterCountMin > 0 && !questionValues.length) ||
        questionValues.some(
          ({ value }) => value.length < characterCountMin || value.length > characterCountMax
        )
      ) {
        errors.push(Rules.LENGTH_RANGE);
      }
      break;
    default:
  }

  return errors;
};

export const validateCurrentSection = () => (dispatch, getRootState) => {
  const state = getRootState();
  const currentSection = getCurrentSection(state);
  const currentQuestionValues = getCurrentQuestionValues(state);
  const questionErrors = {};

  for (const { question } of currentSection.blocks) {
    if (!question) continue;

    const values = currentQuestionValues?.[question.id];
    const errors = [
      validateRequired(question, values),
      validateHardCapped(question, values),
      validateNumeric(question, values),
      validateChoicesLimit(question, values),
      validateBaseQuestion(question, values),
    ].flat();

    if (errors.length) {
      questionErrors[question.id] = errors;
    }
  }

  dispatch({
    type: VALIDATE_CURRENT_SECTION,
    payload: { questionErrors },
  });

  return questionErrors;
};

export const saveResponses =
  (position, nextSectionPosition, responseHistory) => async (dispatch, getRootState) => {
    const state = getRootState();
    const sections = getSections(state);
    const engagement = deserializeEngagement(state);
    const questionsMap = getEngagementQuestionsMap(state);
    const skipSections = generateSkipSections(state);
    const submissionTracked = getSubmissionTracked(state);
    const submission = getSubmission(state);
    const sectionsSubmitted = getSectionsSubmitted(state);
    const questionValues = getQuestionValues(state);
    const fingerprint = getFingerprint(state);
    const newQuestionValues = questionValues[position] || {};
    const section = sections[position];
    const nextSection = findNextSectionPosition(skipSections, sections, nextSectionPosition);

    for (const [questionId, value] of Object.entries(newQuestionValues)) {
      if (isEmpty(value)) continue;
      upsertResponse(questionsMap[questionId], submission, value);
    }

    // Send response history if not empty
    if (responseHistory.length > 0) {
      const history = mergeHistoryUpdates(responseHistory);

      for (const entry of history) {
        entry.data.digitalFingerprint = fingerprint;
      }

      await responseService.responsesCreated({
        submissionUuid: submission.guid,
        engagementUuid: engagement.uuid,
        sectionUuid: section.uuid,
        clientId: window.gon.clientId,
        history,
      });
    }

    // Notify rails that section is completed if not yet notified
    if (!sectionsSubmitted.includes(section.id)) {
      createSectionSubmission(section, submission);
    }

    // Notify response microservice that section is completed
    await responseService.sectionCompleted({
      submissionUuid: submission.guid,
      engagementUuid: engagement.uuid,
      sectionUuid: section.uuid,
      clientId: window.gon.clientId,
    });

    // Notify rails and response microservice that submission is completed
    if (!nextSection) {
      await completeSubmission(submission.id, engagement.id);
      await responseService.submissionCompleted(submission.guid, {
        engagementUuid: engagement.uuid,
        completedAt: new Date().toISOString(),
        clientId: window.gon.clientId,
      });
    }

    // Track submission event
    if (!submissionTracked && responseHistory.length > 0) {
      logParticipantSubmission('Engagement', engagement.id, engagement.project.id);
      dispatch({ type: TRACK_SUBMISSION });
    }

    dispatch({ type: UPDATE_SECTIONS_SUBMITTED, payload: { sectionId: section.id } });
  };

export const resetResponseHistory = () => ({ type: RESET_RESPONSE_HISTORY });

export const ensureSubmission = () => async (dispatch, getRootState) => {
  const state = getRootState();

  if (getSubmission(state)) return;

  const engagement = deserializeEngagement(state);
  const params = new URLSearchParams(window.location.search);
  const submissionUuid = engagement.requiresCode ? params.get('code') : uuid();
  const fingerprintConsent = getFingerprintConsent(state);
  let fingerprint = null;

  if (engagement.collectDigitalFingerprint && fingerprintConsent !== false) {
    const agent = await FingerprintJS.load();
    const result = await agent.get();
    fingerprint = result.visitorId;
  }

  const requests = [
    createSubmission(submissionUuid, params.get('code'), engagement.id, fingerprint),
    responseService.submissionCreated({
      uuid: submissionUuid,
      engagementUuid: engagement.uuid,
      inviteeId: window.gon.inviteeId,
      userAgent: navigator.userAgent,
      digitalFingerprint: fingerprint,
      clientId: window.gon.clientId,
    }),
  ];

  const [submissionSerialized] = await Promise.all(requests);
  const submission = jsonaDeserialize(submissionSerialized);

  dispatch({ type: SAVE_SUBMISSION, payload: { submission, fingerprint } });
};

export const updateFingerprintConsent = (fingerprintConsent) => ({
  type: UPDATE_FINGERPRINT_CONSENT,
  payload: { fingerprintConsent },
});

export const pushResponseEntry = (entry) => ({
  type: PUSH_RESPONSE_ENTRY,
  payload: { entry },
});

export const updateResponseInputsMap = (inputsMap) => ({
  type: UPDATE_RESPONSE_INPUTS_MAP,
  payload: { inputsMap },
});

/**
 * @param {{
 *   inputId: string,
 *   questionId: string,
 *   weightId?: string,
 *   inputAnswer?: string,
 * }} params
 */
export const deleteResponseEntry = (params) => (dispatch, getRootState) => {
  const { inputId, questionId, weightId, inputAnswer } = params;
  const state = getRootState();
  const responseInputsMap = { ...getResponseInputsMap(state) };
  if (!(inputId in responseInputsMap)) return;

  const entry = deleteEntry({
    responseUuid: responseInputsMap[inputId],
    questionUuid: getEngagementQuestionsMap(state)[questionId].uuid,
    inputUuid: getQuestionInputsMap(state)[inputId].uuid,
    inputOptionUuid: getQuestionWeightsMap(state)[weightId]?.uuid,
    inputAnswer,
  });

  delete responseInputsMap[inputId];
  dispatch(pushResponseEntry(entry));
  dispatch(updateResponseInputsMap(responseInputsMap));
};

/**
 * @param {{
 *   inputId: string,
 *   questionId: string,
 *   weightId?: string,
 *   inputAnswer?: string,
 * }} params
 */
export const upsertResponseEntry = (params) => (dispatch, getRootState) => {
  const { inputId, questionId, weightId, inputAnswer } = params;
  const state = getRootState();
  const responseInputsMap = { ...getResponseInputsMap(state) };

  const data = {
    responseUuid: responseInputsMap[inputId] || uuid(),
    questionUuid: getEngagementQuestionsMap(state)[questionId].uuid,
    inputUuid: getQuestionInputsMap(state)[inputId].uuid,
    inputOptionUuid: getQuestionWeightsMap(state)[weightId]?.uuid,
    inputAnswer,
  };

  const entry = inputId in responseInputsMap ? patchEntry(data) : postEntry(data);
  responseInputsMap[inputId] = data.responseUuid;
  dispatch(pushResponseEntry(entry));
  dispatch(updateResponseInputsMap(responseInputsMap));
};

// Reducer
export default createReducer(INITIAL_STATE, {
  [SET_PERSISTED_ENGAGEMENT]: (state, action) => ({ ...state, ...action.payload }),
  [CLEAR_USER_DATA]: (state) => ({
    ...INITIAL_STATE,
    stateHydrated: state.stateHydrated,
    engagementSerialized: state.engagementSerialized,
  }),
  [UPDATE_SECTIONS_SUBMITTED]: (state, action) => {
    const { sectionsSubmitted } = state;
    const { sectionId } = action.payload;

    if (sectionsSubmitted.includes(sectionId)) return state;

    return {
      ...state,
      sectionsSubmitted: [...sectionsSubmitted, sectionId],
    };
  },
  [UPDATE_CURRENT_POSITION]: (state, action) => ({ ...state, ...action.payload }),
  [UPDATE_QUESTION_VALUE]: (state, action) => {
    const { currentSectionPosition, questionValues } = state;
    const { id, value, append } = action.payload;
    const currentValue = questionValues?.[currentSectionPosition]?.[id];

    return {
      ...state,
      questionValues: {
        ...questionValues,
        [currentSectionPosition]: {
          ...questionValues?.[currentSectionPosition],
          [id]: append ? { ...currentValue, ...value } : value,
        },
      },
    };
  },
  [VALIDATE_CURRENT_SECTION]: (state, action) => ({ ...state, ...action.payload }),
  [SAVE_SUBMISSION]: (state, action) => ({
    ...state,
    submission: action.payload.submission,
    fingerprint: action.payload.fingerprint,
  }),
  [UPDATE_FINGERPRINT_CONSENT]: (state, action) => ({
    ...state,
    fingerprintConsent: action.payload.fingerprintConsent,
  }),
  [PUSH_RESPONSE_ENTRY]: (state, action) => ({
    ...state,
    responseHistory: state.responseHistory.concat(action.payload.entry),
  }),
  [RESET_RESPONSE_HISTORY]: (state) => ({
    ...state,
    responseHistory: INITIAL_STATE.responseHistory,
  }),
  [UPDATE_RESPONSE_INPUTS_MAP]: (state, action) => ({
    ...state,
    responseInputsMap: action.payload.inputsMap,
  }),
  [TRACK_SUBMISSION]: (state) => ({
    ...state,
    submissionTracked: true,
  }),
});
