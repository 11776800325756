import { patchRequest, postRequest, putRequest } from './helpers';

/**
 * @param {{
 *   uuid: string,
 *   engagementUuid: string,
 *   inviteeId?: number,
 *   userAgent: string,
 *   digitalFingerprint?: string,
 *   clientId: string
 * }} body
 */
export function submissionCreated(body) {
  return postRequest('/submissions', body);
}

/**
 * @param {string} uuid
 * @param {{
 *   engagementUuid: string,
 *   completedAt: string,
 *   clientId: string
 * }} body
 */
export function submissionCompleted(uuid, body) {
  return patchRequest(`/submissions/${uuid}`, body);
}

/**
 * @param {{
 *   engagementUuid: string,
 *   submissionUuid: string,
 *   sectionUuid: string,
 *   clientId: string
 * }} body
 */
export function sectionCompleted(body) {
  return postRequest('/section_submissions', body);
}

/**
 * @param {{
 *   engagementUuid: string,
 *   submissionUuid: string,
 *   sectionUuid: string,
 *   history: Array<{
 *     method: 'POST' | 'PATCH' | 'DELETE',
 *     data: {
 *       responseUuid: string,
 *       questionUuid: string,
 *       inputUuid: string,
 *       inputOptionUuid: string | null,
 *       inputAnswer: string,
 *       language: string,
 *       defaultLanguage: string,
 *       timestamp: string,
 *       digitalFingerprint: string | null,
 *       clientId: string
 *     }
 *   }>
 * }} body
 */
export function responsesCreated(body) {
  return putRequest('/responses', body);
}
