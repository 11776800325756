// Libraries
import { createSelector } from 'reselect';

// Helpers
import createReducer from 'state/helpers/createReducer';

// Utilities
import { jsonaDeserialize } from 'common/utils/helpers';
import { fetchQuestions } from 'api/rest';

// Enumerables
import QuestionFormat from 'engagement/enums/QuestionFormat';

const isQuantitativeQuestion = ({ format }) =>
  [QuestionFormat.SINGLE_SELECT, QuestionFormat.MULTI_SELECT, QuestionFormat.DROPDOWN].includes(
    format
  );

// Initial State
const INITIAL_STATE = Object.freeze({
  questions: [],
  languages: null,
  filterQuestionId: '',
  filterQuestionFormat: null,
  filterInputIds: [],
  filterExpanded: false,
  compareQuestionId: '',
  compareExpanded: false,
  languageExpanded: false,
  completedSubmissions: false,
  completedSubmissionsExpanded: false,
  excludeSubmissionsExpanded: false,
  excludeDuplicateFingerprints: false,
  excludeDuplicateIPs: false,
  excludeDuplicates: false,
  loading: false,
  compareQuestion: null,
  stateHydrated: false,
});

export const persistKey = ({ engagementId }) => `state/admin/engagement[${engagementId}]/reporting`;
export const persistProps = [
  'filterQuestionId',
  'filterInputIds',
  'filterExpanded',
  'compareQuestionId',
  'compareExpanded',
  'languages',
  'languageExpanded',
  'completedSubmissions',
  'completedSubmissionsExpanded',
  'excludeSubmissionsExpanded',
  'excludeDuplicateFingerprints',
  'excludeDuplicateIPs',
  'excludeDuplicates',
  'excludeMissingFingerprints',
];

// Action Types
export const UPDATE_FILTER = 'UPDATE_FILTER';
export const UPDATE_COMPARE = 'UPDATE_COMPARE';
export const UPDATE_LANGUAGE = 'UPDATE_LANGUAGE';
export const CLEAR_FILTER = 'CLEAR_FILTER';
export const CLEAR_COMPARE = 'CLEAR_COMPARE';
export const CLEAR_LANGUAGE = 'CLEAR_LANGUAGE';
export const UPDATE_FILTER_EXPANDED = 'UPDATE_FILTER_EXPANDED';
export const UPDATE_COMPARE_EXPANDED = 'UPDATE_COMPARE_EXPANDED';
export const UPDATE_LANGUAGE_EXPANDED = 'UPDATE_LANGUAGE_EXPANDED';
export const UPDATE_COMPLETED_SUBMISSIONS = 'UPDATE_COMPLETED_SUBMISSIONS';
export const UPDATE_COMPLETED_SUBMISSIONS_EXPANDED = 'UPDATE_COMPLETED_SUBMISSIONS_EXPANDED';
export const UPDATE_EXCLUDE_SUBMISSIONS_EXPANDED = 'UPDATE_EXCLUDE_SUBMISSIONS_EXPANDED';
export const UPDATE_EXCLUDE_DUPLICATES = 'UPDATE_EXCLUDE_DUPLICATES';
export const START_LOADING = 'START_LOADING';
export const LOAD_QUESTIONS = 'LOAD_QUESTIONS';
export const SET_PERSISTED_REPORTING = 'SET_PERSISTED_REPORTING';

// Selectors
export const getState = (rootState) => rootState.reporting;

export const getQuestions = createSelector(getState, (state) => state.questions);
export const getLanguages = createSelector(getState, (state) => state.languages);
export const getFilterQuestionId = createSelector(getState, (state) => state.filterQuestionId);
export const getFilterQuestionFormat = createSelector(
  getState,
  (state) => state.filterQuestionFormat
);
export const getFilterInputIds = createSelector(getState, (state) => state.filterInputIds);
export const getFilterExpanded = createSelector(getState, (state) => state.filterExpanded);
export const getCompareQuestionId = createSelector(getState, (state) => state.compareQuestionId);
export const getCompareExpanded = createSelector(getState, (state) => state.compareExpanded);
export const getLanguageExpanded = createSelector(getState, (state) => state.languageExpanded);
export const getCompletedSubmissions = createSelector(
  getState,
  (state) => state.completedSubmissions
);
export const getCompletedSubmissionsExpanded = createSelector(
  getState,
  (state) => state.completedSubmissionsExpanded
);
export const getExcludeSubmissionsExpanded = createSelector(
  getState,
  (state) => state.excludeSubmissionsExpanded
);
export const getExcludeDuplicateFingerprints = createSelector(
  getState,
  (state) => state.excludeDuplicateFingerprints
);
export const getExcludeDuplicateIPs = createSelector(
  getState,
  (state) => state.excludeDuplicateIPs
);
export const getExcludeDuplicates = createSelector(getState, (state) => state.excludeDuplicates);
export const getLoading = createSelector(getState, (state) => state.loading);

export const filterQuantitativeQuestions = createSelector([getQuestions], (questions) =>
  questions.filter(isQuantitativeQuestion)
);
export const getFilterQuestion = createSelector(
  [getQuestions, getFilterQuestionId],
  (questions, filterQuestionId) => questions.find(({ id }) => id === filterQuestionId)
);
export const getFilterActive = createSelector(
  [getFilterInputIds],
  (filterInputIds) => !!filterInputIds.length
);
export const getCompareActive = createSelector(
  [getCompareQuestionId],
  (compareQuestionId) => !!compareQuestionId
);

export const getFilterParams = createSelector(
  [
    getFilterQuestionId,
    getFilterQuestionFormat,
    getFilterInputIds,
    getCompareQuestionId,
    getLanguages,
    getCompletedSubmissions,
    getExcludeDuplicateFingerprints,
    getExcludeDuplicateIPs,
    getExcludeDuplicates,
  ],
  (
    filterQuestionId,
    filterQuestionFormat,
    filterInputIds,
    compareQuestionId,
    languages,
    completedSubmissions,
    excludeDuplicateFingerprints,
    excludeDuplicateIPs,
    excludeDuplicates
  ) => {
    const params = {};
    if (filterQuestionId && filterQuestionFormat && filterInputIds.length) {
      params.filter_question_id = filterQuestionId;
      params.filter_question_format = filterQuestionFormat;
      params.filter_values = JSON.stringify(
        filterInputIds.map((inputId) => ({ [inputId]: { value: true } }))
      );
    }
    if (compareQuestionId) {
      params.compare_question_id = compareQuestionId;
    }
    if (languages) {
      params.languages = languages;
    }
    if (completedSubmissions) {
      params.completed_submissions = completedSubmissions;
    }
    if (excludeDuplicateFingerprints) {
      params.exclude_duplicate_fingerprints = excludeDuplicateFingerprints;
    }
    if (excludeDuplicateIPs) {
      params.exclude_duplicate_ips = excludeDuplicateIPs;
    }
    if (excludeDuplicates) {
      params.exclude_duplicates = excludeDuplicates;
    }
    return params;
  }
);

// Actions
export const updateFilter = (filterQuestionId, filterQuestionFormat, filterInputIds) => ({
  type: UPDATE_FILTER,
  payload: { filterQuestionId, filterQuestionFormat, filterInputIds },
});

export const updateCompare = (compareQuestionId) => ({
  type: UPDATE_COMPARE,
  payload: { compareQuestionId },
});

export const updateLanguages = (languages) => ({ type: UPDATE_LANGUAGE, payload: { languages } });

export const clearFilter = () => ({ type: CLEAR_FILTER });

export const clearCompare = () => ({ type: CLEAR_COMPARE });

export const updateFilterExpanded = (payload) => ({ type: UPDATE_FILTER_EXPANDED, payload });

export const updateCompareExpanded = (payload) => ({ type: UPDATE_COMPARE_EXPANDED, payload });

export const updateLanguageExpanded = (payload) => ({ type: UPDATE_LANGUAGE_EXPANDED, payload });

export const updateCompletedSubmissions = (payload) => ({
  type: UPDATE_COMPLETED_SUBMISSIONS,
  payload,
});

export const updateCompletedSubmissionsExpanded = (payload) => ({
  type: UPDATE_COMPLETED_SUBMISSIONS_EXPANDED,
  payload,
});

export const updateExcludeSubmissionsExpanded = (payload) => ({
  type: UPDATE_EXCLUDE_SUBMISSIONS_EXPANDED,
  payload,
});

export const updateExcludeDuplicates = (payload) => ({
  type: UPDATE_EXCLUDE_DUPLICATES,
  payload,
});

export const startLoading = () => ({ type: START_LOADING });

export const loadQuestions =
  (
    currentfilterQuestionId,
    currentfilterActive,
    currentfilterInputIds,
    currentcompareQuestionId,
    currentcompareActive,
    engagementId,
    includeResults = false
  ) =>
  async (dispatch) => {
    const rawQuestions = await fetchQuestions({
      include_results: includeResults,
      engagementId,
    });

    const questions = jsonaDeserialize(rawQuestions);

    const payload = {
      loading: false,
      questions,
    };

    const quantitativeQuestions = questions.filter(isQuantitativeQuestion);

    if (currentfilterQuestionId) {
      const filterQuestion = quantitativeQuestions.find(({ id }) => id === currentfilterQuestionId);

      if (filterQuestion && currentfilterActive) {
        const inputIds = filterQuestion.inputs.map(({ id }) => id);
        const inputsExist = currentfilterInputIds.every((id) => inputIds.includes(id));

        if (inputsExist) {
          payload.filterQuestionFormat = filterQuestion.format;
        } else {
          payload.filterQuestionId = INITIAL_STATE.filterQuestionId;
          payload.filterInputIds = INITIAL_STATE.filterInputIds;
        }
      }
    }

    if (currentcompareQuestionId) {
      const compareQuestion = quantitativeQuestions.find(
        ({ id }) => id === currentcompareQuestionId
      );

      if (!compareQuestion && currentcompareActive) {
        payload.compareQuestionId = INITIAL_STATE.compareQuestionId;
      }
    }

    dispatch({ type: LOAD_QUESTIONS, payload });
  };

// Actions
export const setPersistedReporting = (persistedState, engagementLanguages) => ({
  type: SET_PERSISTED_REPORTING,
  payload: {
    ...persistedState,
    languages: persistedState.languages
      ? persistedState.languages.filter((language) => engagementLanguages.includes(language))
      : engagementLanguages,
  },
});

// Reducer
export default createReducer(INITIAL_STATE, {
  [UPDATE_FILTER]: (state, action) => ({
    ...state,
    ...action.payload,
  }),
  [UPDATE_COMPARE]: (state, action) => ({
    ...state,
    ...action.payload,
  }),
  [UPDATE_LANGUAGE]: (state, action) => ({
    ...state,
    ...action.payload,
  }),
  [CLEAR_FILTER]: (state) => ({
    ...state,
    filterQuestionId: INITIAL_STATE.filterQuestionId,
    filterQuestionFormat: INITIAL_STATE.filterQuestionFormat,
    filterInputIds: INITIAL_STATE.filterInputIds,
  }),
  [CLEAR_COMPARE]: (state) => ({
    ...state,
    compareQuestionId: INITIAL_STATE.compareQuestionId,
  }),
  [CLEAR_LANGUAGE]: (state) => ({
    ...state,
    languages: [],
  }),
  [UPDATE_FILTER_EXPANDED]: (state, { payload }) => ({
    ...state,
    filterExpanded: payload,
  }),
  [UPDATE_COMPARE_EXPANDED]: (state, { payload }) => ({
    ...state,
    compareExpanded: payload,
  }),
  [UPDATE_LANGUAGE_EXPANDED]: (state, { payload }) => ({
    ...state,
    languageExpanded: payload,
  }),
  [UPDATE_COMPLETED_SUBMISSIONS]: (state, { payload }) => ({
    ...state,
    completedSubmissions: payload,
  }),
  [UPDATE_COMPLETED_SUBMISSIONS_EXPANDED]: (state, { payload }) => ({
    ...state,
    completedSubmissionsExpanded: payload,
  }),
  [UPDATE_EXCLUDE_DUPLICATES]: (state, { payload }) => ({
    ...state,
    excludeDuplicates: payload,
  }),
  [UPDATE_EXCLUDE_SUBMISSIONS_EXPANDED]: (state, { payload }) => ({
    ...state,
    excludeSubmissionsExpanded: payload,
  }),
  [START_LOADING]: (state) => ({ ...state, loading: true }),
  [LOAD_QUESTIONS]: (state, action) => ({
    ...state,
    ...action.payload,
  }),
  [SET_PERSISTED_REPORTING]: (state, action) => ({
    ...state,
    ...action.payload,
    stateHydrated: true,
  }),
});
