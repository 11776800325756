// Libraries
import axios from 'axios';
import axiosRetry from 'axios-retry';

// Utils
import { logError } from 'common/utils/errors';

axiosRetry(axios, {
  retries: 4,
  retryDelay: axiosRetry.exponentialDelay,
});

async function request(endpoint, method, data, headers = {}) {
  try {
    const slashedEndpoint = endpoint.startsWith('/') ? endpoint : `/${endpoint}`;
    const url =
      (localStorage.getItem('engage-frontend-gateway')
        ? 'https://frontend-gateway-eng-stg.zencity.io'
        : window.gon.responseServiceApiUrl) + slashedEndpoint;
    const response = await axios.request({
      url,
      method,
      headers: {
        'x-zc-client-id': window.gon.clientId,
        ...headers,
      },
      data,
    });

    if (response.status === 204) {
      return '';
    }
    return response.data;
  } catch (error) {
    let context;
    if (error.response) {
      context = { response: error.response };
    } else if (error.request) {
      context = { request: error.request };
    } else {
      context = { message: error.message };
    }

    logError(error, {
      context,
    });
    throw error;
  }
}

export function getRequest(endpoint, headers = {}) {
  return request(endpoint, 'GET', headers);
}

export function postRequest(endpoint, data, headers = {}) {
  return request(endpoint, 'POST', data, headers);
}

export function patchRequest(endpoint, data, headers = {}) {
  return request(endpoint, 'PATCH', data, headers);
}

export function putRequest(endpoint, data, headers = {}) {
  return request(endpoint, 'PUT', data, headers);
}
