const conditions = Object.freeze({
  IS_EMPTY: 'is_empty',
  IS_NOT_EMPTY: 'is_not_empty',
  IS_EXACTLY: 'is_exactly',
  INCLUDES_ALL_OF: 'includes_all_of',
  INCLUDES_ONE_OF: 'includes_one_of',
  INCLUDES_NONE_OF: 'includes_none_of',
});

export const {
  IS_EMPTY,
  IS_NOT_EMPTY,
  IS_EXACTLY,
  INCLUDES_ALL_OF,
  INCLUDES_ONE_OF,
  INCLUDES_NONE_OF,
} = conditions;
