import { apiCall } from '../helper.js';

export function upsertQuestionLogic(body) {
  return apiCall('/admin/question_logics', 'POST', JSON.stringify(body));
}

export function destroyQuestionLogics(id) {
  return apiCall(
    `/admin/question_logics/${id}`,
    'DELETE',
    JSON.stringify({
      data: { type: 'question_logic' },
    })
  );
}
