import { currentLanguage } from 'i18n';
import { apiCall } from './helper';

export function createSubmission(guid, inviteCode, engagementId, fingerprint) {
  const body = JSON.stringify({
    data: {
      type: 'submission',
      attributes: {
        guid,
        code: inviteCode,
        engagement_id: engagementId,
        user_agent: navigator.userAgent,
        locale: currentLanguage,
        digital_fingerprint: fingerprint,
      },
    },
  });

  return apiCall(`/engagements/${engagementId}/submissions`, 'POST', body);
}

export function completeSubmission(submissionId, engagementId) {
  const body = JSON.stringify({
    data: {
      type: 'submission',
      attributes: {
        locale: currentLanguage,
      },
    },
  });
  return apiCall(`/engagements/${engagementId}/submissions/${submissionId}/complete`, 'POST', body);
}
