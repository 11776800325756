export default Object.freeze({
  ALL_ROWS_REQUIRED: 'allRowsRequired',
  REQUIRED: 'required',
  BUDGET: 'budget',
  NUMBER: 'number',
  NUMBER_RANGE: 'numberRange',
  LENGTH_RANGE: 'lengthRange',
  VALIDATION_TYPE: 'validationType',
  REGULAR_EXPRESSION: 'regularExpression',
  EXCEEDED_MAX_CHOICES: 'exceededMaxChoices',
});
