// Helpers
import createReducer from 'state/helpers/createReducer';

// Utilities
import { jsonaDeserialize } from 'common/utils/helpers';
import { fetchSections } from 'api/rest';

// Enumerables
import { AFTER } from 'engagement/enums/Positions';

// Initial State
const INITIAL_STATE = {
  moveQuestionModalOpen: false,
  blockToMoveId: '',
  loadingSections: true,
  sections: [],
  selectedSection: {},
  selectedPosition: '',
  selectedBlock: {},
};

// Action Types
export const TOGGLE_MOVE_QUESTION_MODAL = 'TOGGLE_MOVE_QUESTION_MODAL';
export const OPEN_MODAL = 'OPEN_MODAL';
export const SELECT_SECTION = 'SELECT_SECTION';
export const SELECT_POSITION = 'SELECT_POSITION';
export const SELECT_BLOCK = 'SELECT_BLOCK';
export const CLEAR_STATE = 'CLEAR_STATE';
export const LOAD_SECTIONS = 'LOAD_SECTIONS';

// Actions
export const toggleMoveQuestionModal = () => async (dispatch) => {
  dispatch({ type: TOGGLE_MOVE_QUESTION_MODAL });
};

export const selectSection = (selectedSection) => async (dispatch) => {
  const selectedPosition = selectedSection.blocks.length ? AFTER : '';
  dispatch({
    type: SELECT_SECTION,
    payload: {
      selectedSection,
      selectedPosition,
      selectedBlock: '',
    },
  });
};

export const openModal = (payload) => async (dispatch) => {
  dispatch({ type: OPEN_MODAL, payload });
};

export const selectPosition = (payload) => async (dispatch) => {
  dispatch({ type: SELECT_POSITION, payload });
};

export const selectBlock = (payload) => async (dispatch) => {
  dispatch({ type: SELECT_BLOCK, payload });
};

export const clearState = () => async (dispatch) => {
  dispatch({
    type: CLEAR_STATE,
    payload: {
      selectedSection: INITIAL_STATE.selectedSection,
      selectPosition: INITIAL_STATE.selectPosition,
      selectedBlock: INITIAL_STATE.selectedBlock,
    },
  });
};

export const loadSections = ({ engagementId }) => async (dispatch) => {
  const rawSections = await fetchSections({
    engagementId,
  });
  const sections = jsonaDeserialize(rawSections).slice(1, -1);
  dispatch({
    type: LOAD_SECTIONS,
    payload: {
      loadingSections: false,
      sections,
    },
  });
};

// Reducer
export default createReducer(INITIAL_STATE, {
  [TOGGLE_MOVE_QUESTION_MODAL]: (state) => ({
    ...state,
    moveQuestionModalOpen: !state.moveQuestionModalOpen,
  }),
  [OPEN_MODAL]: (state, action) => ({
    ...state,
    blockToMoveId: action.payload,
    moveQuestionModalOpen: true,
  }),
  [SELECT_SECTION]: (state, action) => ({ ...state, ...action.payload }),
  [SELECT_POSITION]: (state, action) => ({ ...state, selectedPosition: action.payload }),
  [SELECT_BLOCK]: (state, action) => ({ ...state, selectedBlock: action.payload }),
  [CLEAR_STATE]: (state, action) => ({ ...state, ...action.payload }),
  [LOAD_SECTIONS]: (state, action) => ({ ...state, ...action.payload }),
});
