import ideation from 'state/ducks/ideation';
import questionOptions from 'state/ducks/questionOptions';
import sectionOptions from 'state/ducks/sectionOptions';
import engagement from 'state/ducks/engagement';
import reporting from 'state/ducks/reporting';

export const reducer = {
  ideation,
  questionOptions,
  sectionOptions,
  engagement,
  reporting,
};
