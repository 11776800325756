import { logAmplitudeEvent } from 'common/utils/analytics';

export function logParticipantSubmission(moduleType, moduleId, parentProjectId) {
  logAmplitudeEvent({
    name: 'engage-participant-submissions',
    props: {
      moduleType,
      moduleId: Number(moduleId),
      type: moduleType.toLowerCase(),
      parentProjectId: Number(parentProjectId),
      clientId: window.gon.clientId,
      url: window.location.href,
    },
  });
}
