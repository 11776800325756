// Libraries
import isPast from 'date-fns/isPast';
import parseISO from 'date-fns/parseISO';
import { isEqual } from 'underscore';

// Enumerables
import PublicStatus from 'common/enums/PublicStatus';
import {
  IS_EMPTY,
  IS_NOT_EMPTY,
  IS_EXACTLY,
  INCLUDES_ALL_OF,
  INCLUDES_ONE_OF,
  INCLUDES_NONE_OF,
} from 'engagement/enums/SkipLogic';

/**
 * Check if engagement is closed.
 * @param {Object} engagement
 * @returns {boolean}
 */
export const isEngagementClosed = ({ publicStatus, endTime }) =>
  publicStatus === PublicStatus.CLOSED || (endTime && isPast(parseISO(endTime)));

/**
 * Check if question should randomize answer options.
 * @param {Object} question
 * @returns {boolean}
 */
export const shouldRandomizeInputs = (question) =>
  Boolean(Number(question?.displayData?.randomizeInputPositions));

/**
 * Check to see if section should be skipped
 * @param {Integer} questionId
 * @param {Object} questionResponse
 * @param {Object} questionLogic
 * @returns {boolean}
 */
export const checkSkipLogic = (questionId, questionResponse, questionLogic) => {
  const response = questionResponse ? questionResponse[questionId] : {};
  const { conditional } = questionLogic;
  const { inputIds, operator } = JSON.parse(conditional);
  const inputIdStrings = inputIds ? inputIds.map((inputId) => inputId.toString()) : [];
  const responseInputs = Object.keys(response);
  const includesOne = responseInputs.some((responseInput) =>
    inputIdStrings.includes(responseInput)
  );

  switch (operator) {
    case IS_EMPTY:
      return !questionResponse;
    case IS_NOT_EMPTY:
      return questionResponse;
    case IS_EXACTLY:
      // This check equivalency between the response object agsint the inputIds from the questionLogic
      // mapped to represent true values for single_select and multi_select options. This will need
      // refactoring when more question formats are added
      return isEqual(
        response,
        inputIds.reduce((acc, id) => {
          Object.assign(acc, { [id]: { value: true } });
          return acc;
        }, {})
      );
    case INCLUDES_ALL_OF:
      return inputIdStrings.every((inputIdString) => responseInputs.includes(inputIdString));
    case INCLUDES_ONE_OF:
      return includesOne;
    case INCLUDES_NONE_OF:
      return !includesOne;
    default:
      return false;
  }
};

export const confirmValidMarkers = (values, xAxis, yAxis) =>
  values.filter(
    (value) =>
      value.guid &&
      typeof value.guid === 'string' &&
      value[xAxis] &&
      typeof value[xAxis] === 'number' &&
      value[yAxis] &&
      typeof value[yAxis] === 'number'
  );
