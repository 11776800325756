// TODO: Move code unrelated to state out of this duck (side effects such as submitQuestionLogic for example)

// Helpers
import createReducer from 'state/helpers/createReducer';

// Utilities
import { jsonaDeserialize } from 'common/utils/helpers';
import { fetchSections, upsertQuestionLogic, destroyQuestionLogics, updateSection } from 'api/rest';

// Enumerables
import { SHOW } from 'engagement/enums/LogicalActions';
import QuestionFormat from 'engagement/enums/QuestionFormat';

// Initial State
const INITIAL_STATE = {
  sectionOptionsModalOpen: false,
  loadingQuestions: true,
  questions: [],
  currentSection: {},
  selectedQuestionId: '',
  selectedQuestion: {},
  selectedAction: '',
  inputIds: [],
  conditionalOperator: '',
  // Currently hard-coded to true as the checkbox is no longer present in SectionOptionsForm
  questionLogicEnabled: true,
};

// Helpers
const checkExistingLogic = (
  question,
  currentConditionalOperator,
  currentInputIds,
  currentSection
) => {
  const questionLogic = question.questionLogics.find(
    (logic) => logic.logicalId === currentSection.id
  );
  if (questionLogic) {
    const { action, conditional } = questionLogic;
    const { operator, inputIds } = JSON.parse(conditional);
    return {
      selectedAction: action,
      conditionalOperator: operator,
      inputIds,
    };
  }

  return {
    conditionalOperator: currentConditionalOperator,
    inputIds: currentInputIds,
  };
};

// Action Types
export const TOGGLE_SECTION_OPTIONS_MODAL = 'TOGGLE_SECTION_OPTIONS_MODAL';
export const TOGGLE_DELETE_LOGIC_MODAL = 'TOGGLE_DELETE_LOGIC_MODAL';
export const SELECT_QUESTION = 'SELECT_QUESTION';
export const SET_SHOW_ACTION = 'SET_SHOW_ACTION';
export const SELECT_INPUTS = 'SELECT_INPUTS';
export const SELECT_CONDITIONAL_OPERATOR = 'SELECT_CONDITIONAL_OPERATOR';
export const LOAD_QUESTIONS = 'LOAD_QUESTIONS';
export const SUBMIT_QUESTION_LOGIC = 'SUBMIT_QUESTION_LOGIC';
export const CLEAR_LOGICAL_OPTIONS = 'CLEAR_LOGICAL_OPTIONS';

// Actions
export const toggleSectionOptionsModal = () => async (dispatch) => {
  dispatch({ type: TOGGLE_SECTION_OPTIONS_MODAL });
};

export const toggleDeleteLogicModal = () => async (dispatch) => {
  dispatch({ type: TOGGLE_DELETE_LOGIC_MODAL });
};

export const selectQuestion = (selectedQuestionId, questions) => async (dispatch) => {
  const selectedQuestion = questions.find(({ id }) => id === selectedQuestionId);
  dispatch({
    type: SELECT_QUESTION,
    payload: {
      selectedQuestion,
      selectedQuestionId,
    },
  });
};

// Remove this when SHOW action no longer needs to be hard-coded
export const setShowAction = (payload) => async (dispatch) => {
  const shouldShow = payload ? SHOW : '';
  dispatch({ type: SET_SHOW_ACTION, payload: shouldShow });
};

export const selectInputs = (payload) => async (dispatch) => {
  dispatch({ type: SELECT_INPUTS, payload });
};

export const selectConditionalOperator = (payload) => async (dispatch) => {
  dispatch({ type: SELECT_CONDITIONAL_OPERATOR, payload });
};

export const loadQuestions = (engagementId, sectionId) => async (dispatch) => {
  // Fetch all sections based on current engagement ID, compile questions from all sections
  // with a position less than the current section as options for logic
  const rawSections = await fetchSections({
    engagementId,
  });
  const sections = jsonaDeserialize(rawSections);
  const currentSection = sections.find((section) => section.id === sectionId);

  // These are the variables to populate the form if a questionLogic already exists
  let selectedQuestionId;
  let inputIds = [];
  let selectedQuestion;
  let selectedAction;
  let conditionalOperator;

  if (currentSection.questionLogic) {
    const { questionLogic } = currentSection;
    const allQuestions = sections.reduce((accumulator, section) => {
      // may have to revert this change
      const questions = accumulator || [];
      return questions.concat(section.questions);
    }, []);
    selectedQuestion = allQuestions.find(
      (question) => Number(question.id) === questionLogic.questionId
    );
    selectedAction = questionLogic.action;
    selectedQuestionId = selectedQuestion ? selectedQuestion.id : '';
    const conditional = JSON.parse(questionLogic.conditional);
    conditionalOperator = conditional.operator;
    inputIds = conditional.inputIds;
  }

  const questions = sections
    .reduce((acc, section) => {
      if (section.position < currentSection.position) {
        acc.push(...section.questions);
      }
      return acc;
    }, [])
    // Filter questions to include only budget, multi, single select, and dropdown for phase 1 of 'skip logic' feature
    .filter((question) =>
      [
        QuestionFormat.MULTI_SELECT,
        QuestionFormat.SINGLE_SELECT,
        QuestionFormat.BUDGET,
        QuestionFormat.DROPDOWN,
      ].includes(question.format)
    );

  const payload = {
    loadingQuestions: false,
    questions,
    currentSection,
    selectedAction,
    selectedQuestionId,
    selectedQuestion,
    conditionalOperator,
    inputIds,
    questionLogicEnabled: currentSection.questionLogicEnabled,
  };

  dispatch({ type: LOAD_QUESTIONS, payload });
};

export const submitQuestionLogic =
  (
    selectedAction,
    selectedQuestionId,
    conditionalOperator,
    inputIds,
    currentSection,
    questionLogicEnabled
  ) =>
  async (dispatch) => {
    // Submit question logic data to Rails API if question logic selected
    if (selectedAction && selectedQuestionId && conditionalOperator) {
      const questionLogicBody = {
        data: {
          type: 'question_logic',
          attributes: {
            action: selectedAction,
            // Default logical_type to 'Section' until additional logical types are added
            logical_type: 'Section',
            // Set logical_id to the section id until additional logical types are added
            logical_id: currentSection.id,
            question_id: selectedQuestionId,
            conditional: JSON.stringify({
              operator: conditionalOperator,
              inputIds: Array.isArray(inputIds) ? inputIds : [inputIds],
            }),
          },
        },
      };

      await upsertQuestionLogic(questionLogicBody);
    }

    // If 'question logic enabled' modified, update the section
    if (currentSection.questionLogicEnabled !== questionLogicEnabled) {
      const sectionBody = {
        data: {
          type: 'section',
          attributes: {
            question_logic_enabled: questionLogicEnabled,
          },
        },
      };

      await updateSection(currentSection.id, sectionBody);
    }

    dispatch({ type: SUBMIT_QUESTION_LOGIC });
  };

export const clearLogicalOptions = (currentSection, questions) => async (dispatch) => {
  const updatedQuestions = [...questions];
  const updatedSection = { ...currentSection };

  if (currentSection.questionLogic) {
    // Delete question logic, and remove existing question logic from state without
    // reloading questions
    await destroyQuestionLogics(currentSection.questionLogic.id);
    const sectionQuestionsWithoutLogic = currentSection.questions.map((question) => ({
      ...question,
      questionLogics: [],
    }));
    updatedQuestions.push(...sectionQuestionsWithoutLogic);
    Object.assign(updatedSection, { questionLogic: null });
  }

  const { selectedQuestionId, selectedQuestion, selectedAction, inputIds, conditionalOperator } =
    INITIAL_STATE;

  const payload = {
    selectedQuestionId,
    selectedQuestion,
    selectedAction,
    inputIds,
    conditionalOperator,
    currentSection: updatedSection,
    questions: updatedQuestions,
  };

  dispatch({ type: CLEAR_LOGICAL_OPTIONS, payload });
};

// Reducer
export default createReducer(INITIAL_STATE, {
  [TOGGLE_SECTION_OPTIONS_MODAL]: (state) => ({
    ...state,
    sectionOptionsModalOpen: !state.sectionOptionsModalOpen,
  }),
  [SELECT_QUESTION]: (state, action) => ({
    ...state,
    ...{
      ...action.payload,
      ...checkExistingLogic(
        action.payload.selectedQuestion,
        state.conditionalOperator,
        state.inputIds,
        state.currentSection
      ),
    },
  }),
  [SET_SHOW_ACTION]: (state, action) => ({
    ...state,
    selectedAction: action.payload,
  }),
  [SELECT_INPUTS]: (state, action) => ({
    ...state,
    inputIds: action.payload,
  }),
  [SELECT_CONDITIONAL_OPERATOR]: (state, action) => ({
    ...state,
    conditionalOperator: action.payload,
  }),
  [LOAD_QUESTIONS]: (state, action) => ({ ...state, ...action.payload }),
  [SUBMIT_QUESTION_LOGIC]: (state) => ({
    ...state,
    sectionOptionsModalOpen: !state.sectionOptionsModalOpen,
  }),
  [CLEAR_LOGICAL_OPTIONS]: (state, action) => ({ ...state, ...action.payload }),
});
