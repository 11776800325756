import { apiCall } from './helper';

export const createSectionSubmission = (section, submission) => {
  const requestBody = JSON.stringify({
    data: {
      type: 'section',
      attributes: {
        submission_id: submission.id,
      },
    },
  });

  apiCall(`/sections/${section.id}`, 'PUT', requestBody);
};
