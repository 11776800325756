import { currentLanguage } from 'i18n';

function entryData(data) {
  const {
    responseUuid,
    questionUuid,
    inputUuid,
    inputOptionUuid = null,
    inputAnswer = '',
    comment = '',
  } = data;

  return {
    responseUuid,
    questionUuid,
    inputUuid,
    inputOptionUuid,
    inputAnswer,
    comment,
    language: currentLanguage,
    defaultLanguage: window.gon.defaultLanguage,
    clientId: window.gon.clientId,
    timestamp: new Date().toISOString(),
  };
}

export function postEntry(data) {
  return { method: 'POST', data: entryData(data) };
}

export function patchEntry(data) {
  return { method: 'PATCH', data: entryData(data) };
}

export function deleteEntry(data) {
  return { method: 'DELETE', data: entryData(data) };
}

export function mergeHistoryUpdates(history) {
  return history.reduce((mergedHistory, entry) => {
    const lastEntry = mergedHistory[mergedHistory.length - 1];

    if (
      entry.method === 'PATCH' &&
      lastEntry?.method === 'PATCH' &&
      entry.data.responseUuid === lastEntry?.data.responseUuid
    ) {
      lastEntry.data = entry.data;
    } else {
      mergedHistory.push(entry);
    }

    return mergedHistory;
  }, []);
}
