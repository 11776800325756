// Helpers
import createReducer from 'state/helpers/createReducer';

// Action Types
export const SET_SUBMISSION_STATUS = 'SET_SUBMISSION_STATUS';
export const CLEAR_SUBMISSION_STATUS = 'CLEAR_SUBMISSION_STATUS';

// Actions
export const setSubmissionStatus = (payload) => async (dispatch) => {
  dispatch({ type: SET_SUBMISSION_STATUS, payload });
};
export const clearSubmissionStatus = () => async (dispatch) => {
  dispatch({ type: CLEAR_SUBMISSION_STATUS });
};

// Initial State
const INITIAL_STATE = {
  submissionStatus: null,
};

// Reducer
export default createReducer(INITIAL_STATE, {
  [SET_SUBMISSION_STATUS]: (state, action) => ({ ...state, submissionStatus: action.payload }),
  [CLEAR_SUBMISSION_STATUS]: (state) => ({ ...state, submissionStatus: null }),
});
